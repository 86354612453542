import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorManager } from 'app/__helpers/errorManager';
import { GlobalSettings } from 'GlobalSettings';
import { Observable } from 'rxjs';
import {GPIDListResponseDTO} from '../_adminMVC/gpidManagement/model/GPIDListResponseDTO';
import {VideoPlacement} from "../__helpers/entities/VideoPlacement";
import {VideoPlaybackMethod} from "../__helpers/entities/VideoPlaybackMethod";
import {VideoPosition} from "../__helpers/entities/VideoPosition";
import {forkJoin} from "rxjs/internal/observable/forkJoin";

@Injectable({
  providedIn: 'root',
})
export class TargetingService {
  private readonly getSuggestedTopicsURL = GlobalSettings.APIurl + '/api/v1/targeting/semanticTopic';
  private readonly getRelatedTopcisURL = GlobalSettings.APIurl + '/api/v1/targeting/relatedTopic';
  private readonly getAllGPIDListsURL = `${GlobalSettings.APIV2url}/v2/gpid/lists/all`;
  private readonly addGPIDListURL = `${GlobalSettings.APIV2url}/v2/gpid/lists/file`;
  private readonly partialUpdateGPIDListURL = `${GlobalSettings.APIV2url}/v2/gpid/lists`;
  private readonly partialDownloadGPIDListURL = `${GlobalSettings.APIV2url}/v2/gpid/lists/file/download`;

  private readonly videoPlacementsPath = GlobalSettings.APIV2url + '/v2/video-placements';
  private readonly videoPlaybackMethodsPath = GlobalSettings.APIV2url + '/v2/video-playback-methods';
  private readonly videoPositionsPath = GlobalSettings.APIV2url + '/v2/video-positions';

  private readonly videoPlacementsPermissionPath = GlobalSettings.APIV2url + '/v2/users/{id}/video-placement-permissions';
  private readonly videoPlacementsPermissionGroupPath = GlobalSettings.APIV2url + '/v2/groups/{id}/video-placement-permissions';
  private readonly videoPlaybackMethodsPermissionPath = GlobalSettings.APIV2url + '/v2/users/{id}/video-playback-methods-permissions';
  private readonly videoPlaybackMethodsPermissionGroupPath = GlobalSettings.APIV2url + '/v2/groups/{id}/video-playback-methods-permissions';
  private readonly videoPositionsPermissionPath = GlobalSettings.APIV2url + '/v2/users/{id}/video-position-permissions';
  private readonly videoPositionsPermissionGroupPath = GlobalSettings.APIV2url + '/v2/groups/{id}/video-position-permissions';

  constructor(private http: HttpClient) {
  }

  getSemanticTopics(lang: string, keyword?: string, wikidata_id?: string): Observable<any> {
    const params: any = {};
    if (keyword !== null) {
      params['keyword'] = keyword;
    }
    if (wikidata_id !== null) {
      params['wikidata_id'] = wikidata_id;
    }
    if (lang !== null) {
      params['lang'] = lang;
    }

    return this.http.get<any>(this.getSuggestedTopicsURL, {params: params}).catch(ErrorManager.handleError);
  }

  getRelatedTopics(wikidata_id: string, lang: string) {
    const params: any = {};
    if (wikidata_id !== null) {
      params['wikidata_id'] = wikidata_id;
    }
    if (lang !== null) {
      params['lang'] = lang;
    }

    return this.http.get<any>(this.getRelatedTopcisURL, {params: params}).catch(ErrorManager.handleError);
  }

  getGPIDLists(): Observable<GPIDListResponseDTO[]> {
    return this.http.get<GPIDListResponseDTO[]>(this.getAllGPIDListsURL);
  }

  getGPIDListsByEntityId(entityId: number|null): Observable<GPIDListResponseDTO[]> {
    const params: any = {};
    if (entityId != null) { params['entityId'] = entityId };

    return this.http.get<GPIDListResponseDTO[]>(this.partialUpdateGPIDListURL, { params: params });
  }

  addGPIDList(data: FormData): Observable<GPIDListResponseDTO> {
    return this.http.post<GPIDListResponseDTO>(this.addGPIDListURL, data);
  }

  updateGPIDList(data: FormData, id: number): Observable<GPIDListResponseDTO> {
    return this.http.put<GPIDListResponseDTO>(`${this.partialUpdateGPIDListURL}/${id}`, data);
  }

  downloadGPIDList(id: number): Observable<any> {
    return this.http.post(`${this.partialDownloadGPIDListURL}/${id}`, null, { responseType: 'arraybuffer' });
  }

  //////////////////////////////////////////////////////
  // Inventory video targeting

  // Video Placements
  getVideoPlacements(id?: number): Observable<VideoPlacement[]> {
    return this.makeGetCall(VideoPlacement, this.videoPlacementsPath, id);
  }

  getVideoPlacementsPermission(id: number): Observable<VideoPlacement[]> {
    return this.makeGetCall(VideoPlacement, this.videoPlacementsPermissionPath, id);
  }

  getVideoPlacementsPermissionSettings(id: number): Observable<VideoPlacement[]> {
    return this.getVideoPlacementsPermission(id);
  }

  getVideoPlacementsPermissionGroupSettings(id: number): Observable<number[]> {
    return this.makeGetCall(VideoPlacement, this.videoPlacementsPermissionGroupPath, id);
  }

  setVideoPlacementsPermission(arr: VideoPlacement[], id: number): Observable<VideoPlacement[]> {
    // const video: VideoPlacement[] = this.setVideoMapper(arr);
    return this.makePostCall(VideoPlacement, this.videoPlacementsPermissionPath, id, arr);
  }

  setVideoPlacementsPermissionGroup(arr: VideoPlacement[], id: number): Observable<VideoPlacement[]> {
    // const video: VideoPlacement[] = this.setVideoMapper(arr);
    return this.makePostCall(VideoPlacement, this.videoPlacementsPermissionGroupPath, id, arr);
  }

  // Video Playback Methods
  getVideoPlaybackMethods(id?: number): Observable<VideoPlaybackMethod[]> {
    if (id) {
      return this.getVideoPlaybackMethodsPermission(id);
    }
    return this.http.get<VideoPlaybackMethod[]>(this.videoPlaybackMethodsPath);
  }

  getVideoPlaybackMethodsPermission(id: number): Observable<VideoPlaybackMethod[]> {
    return this.makeGetCall(VideoPlaybackMethod, this.videoPlaybackMethodsPermissionPath, id);
  }

  getVideoPlaybackMethodsPermissionSettings(id: number): Observable<VideoPlaybackMethod[]> {
    return this.getVideoPlaybackMethodsPermission(id);
  }

  getVideoPlaybackMethodsPermissionGroupSettings(id: number): Observable<number[]> {
    return this.makeGetCall(VideoPlaybackMethod, this.videoPlaybackMethodsPermissionGroupPath, id);
  }

  setVideoPlaybackMethodsPermission(arr: VideoPlaybackMethod[], id: number): Observable<VideoPlaybackMethod[]> {
    const video: VideoPlaybackMethod[] = this.setVideoMapper(arr);
    return this.makePostCall(VideoPlaybackMethod, this.videoPlaybackMethodsPermissionPath, id, video);
  }

  setVideoPlaybackMethodsPermissionGroup(arr: VideoPlaybackMethod[], id: number): Observable<VideoPlaybackMethod[]> {
    const video: VideoPlaybackMethod[] = this.setVideoMapper(arr);
    return this.makePostCall(VideoPlaybackMethod, this.videoPlaybackMethodsPermissionGroupPath, id, video);
  }

  // Video Positions
  getVideoPositions(id?: number): Observable<VideoPosition[]> {
    if (id) {
      return this.getVideoPositionsPermission(id);
    }
    return this.makeGetCall(VideoPosition, this.videoPositionsPath, id);
  }

  getVideoPositionsPermission(id: number): Observable<VideoPlaybackMethod[]> {
    return this.makeGetCall(VideoPlaybackMethod, this.videoPositionsPermissionPath, id);
  }

  getVideoPositionsPermissionSettings(id: number): Observable<VideoPlaybackMethod[]> {
    return this.getVideoPositionsPermission(id);
  }

  getVideoPositionsPermissionGroupSettings(id: number): Observable<number[]> {
    return this.makeGetCall(VideoPlaybackMethod, this.videoPositionsPermissionGroupPath, id);
  }

  setVideoPositionsPermission(arr: VideoPosition[], id: number): Observable<VideoPlaybackMethod[]> {
    const video: VideoPlaybackMethod[] = this.setVideoMapper(arr);
    return this.makePostCall(VideoPlaybackMethod, this.videoPositionsPermissionPath, id, video);
  }

  setVideoPositionsPermissionGroup(arr: VideoPosition[], id: number): Observable<VideoPlaybackMethod[]> {
    const video: VideoPlaybackMethod[] = this.setVideoMapper(arr);
    return this.makePostCall(VideoPlaybackMethod, this.videoPositionsPermissionGroupPath, id, video);
  }

  async getAllInventoryVideo(id?: number) {
    const inventoryVideoPermissions = {
      videoPlaybackMethod: [],
      videoPlacement: [],
      videoPosition: []
    };

    const $videoPlacements = this.getVideoPlacements();
    const $videoPlayback = this.getVideoPlaybackMethods();
    const $videoPosition = this.getVideoPositions();


    const obj = await forkJoin([$videoPlacements, $videoPlayback, $videoPosition]).toPromise();
    this.convertObjectsToSelectedItem(obj[0], inventoryVideoPermissions.videoPlacement);
    this.convertObjectsToSelectedItem(obj[1], inventoryVideoPermissions.videoPlaybackMethod);
    this.convertObjectsToSelectedItem(obj[2], inventoryVideoPermissions.videoPosition);

    if (id) {
      const $videoPlacementsSetting = this.getVideoPlacementsPermissionSettings(id)
      const $videoPlaybackSetting = this.getVideoPlaybackMethodsPermissionSettings(id)
      const $videoPositionSetting = this.getVideoPositionsPermissionSettings(id);

      const settings = await forkJoin([$videoPlacementsSetting, $videoPlaybackSetting, $videoPositionSetting]).toPromise();

      inventoryVideoPermissions.videoPlacement = this.filterPermissions(inventoryVideoPermissions.videoPlacement,settings[0]);
      inventoryVideoPermissions.videoPlaybackMethod = this.filterPermissions(inventoryVideoPermissions.videoPlaybackMethod, settings[1]);
      inventoryVideoPermissions.videoPosition = this.filterPermissions(inventoryVideoPermissions.videoPosition, settings[2]);
    }

    return inventoryVideoPermissions;
  }

  filterPermissions(originalObject: any, filterObject: any) {
    return originalObject.filter(x => filterObject.filter(p => p.id === x.id).length > 0);
  }

  convertObjectsToSelectedItem(origin: any[], destination: any[]) {
    for (const o of origin) {
      destination.push({
        id: o.id,
        value: o.value,
        label: o.name
      });
    }
  }

  makeGetCall(returnObj: any, path: string, id?: number): Observable<typeof returnObj[]> {
    try {
      if (id) {
        return this.http.get<typeof returnObj[]>(path.replace("{id}", id.toString()));
      }
      return this.http.get<typeof returnObj[]>(path);
    } catch (e) {
      console.log('Error to make get call in targetingService.makeGetCall: url {} id {}', path, id);
      return Observable.of([]);
    }
  }

  makePostCall(returnObj: any, path: string, id: number, body: any): Observable<typeof returnObj[]> {
    try {
      return this.http.post<typeof returnObj[]>(path.replace("{id}", id.toString()), body);
    } catch (e) {
      console.log('Error to make get call in targetingService.makePostCall: url {} id {}', path, id);
      return Observable.of([]);
    }
  }

  setVideoMapper(arr: any) {
    const video: VideoPlacement[] = [];
    arr.forEach(x => {
      video.push({
        id: x.id,
        value: x.value,
        name: x.name
      });
    });
    return video;
  }

  //////////////////////////////////////////////////////
}
