<nav id="navbar" class="navbar navbar-inverse navbar-fixed-top navbarDefault" [class.shortView]="shortView" [class.testBackGround]="!prodOrTest" [class.sandBackGround]="sandbox">

  <div class="container-fluid" style="height: 100%">

<!--  Publisher PubID  -->
    <div *ngIf="isAdmin() && isPublisher()" class="pubIdContainer w3-hide-medium w3-hide-small">
      <label *ngIf="myUser?.encryptedId" style="cursor: pointer; margin-right: 5px" ngxClipboard [cbContent]="myUser.encryptedId" (click)="showCopied()" ><b>PubId:</b> {{myUser?.encryptedId}}</label>
      <button *ngIf="isPublisherReportVisible()" (click)="showCopied()" class="copyPubid primary" [pTooltip]="'Copy permalink'" ngxClipboard [cbContent]="getReportStringToCopy()"><i class="fal fa-copy" aria-hidden="true"></i></button>
      <button *ngIf="isPublisherReportVisible()" (click)="getPublisherReport()" class="copyPubid primary" [pTooltip]="'Download permalink'"><i class="fal fa-download"></i></button>
    </div>

<!----------------------------------------------------- Desktop Version------------------------------------------------>

    <ul class="nav navbar-nav navbar-right w3-hide-medium w3-hide-small" style="width: 100%;">
      <li *ngIf="!isInNewCampaign(url) && ( !getExpanded() && !hideExpand)" ><button class="otp-btn default navButtons"  (click)="expand()" style="margin-top: 12px;"> <i class="fal fa-expand" aria-hidden="true"></i></button></li>
      <li *ngIf="!isInNewCampaign(url) && ( getExpanded() && !hideExpand)"><button class="otp-btn default navButtons"   (click)="compress()" style="background-color: #9E9E9E; margin-top: 12px;"> <i class="fal fa-compress" aria-hidden="true"></i></button></li>

      <li class="dropdown">
        <button class="otp-btn navButtons dropdown-toggle" type="button" data-toggle="dropdown" style="display: flex; position: relative; height: 50px;">
          <div *ngIf="(timezones | getElBySpecifiedValue: {value: getTimezone(), prop: 'label'}) as timezonElem" class="greyColor" style="margin-top: 10px">
            <i class="fal fa-clock icon-choice-nav"></i> {{timezonElem?.title}} <i class="fal fa-angle-down" aria-hidden="true"></i></div>
        </button>
        <div class="dropdown-menu" style="width: 330px; padding: 15px;">
          <div *ngFor="let myTime of timezones">
            <button class="otp-btn default dropdown_el" [class.selectedTimezone]="myTime.label === getTimezone()" (click)="setTimezone(myTime)">{{myTime.title}} ({{myTime.label}})</button>
          </div>
        </div>
      </li>


      <!--Dropdown menu utente-->
      <li class="dropdown">
        <button class="otp-btn navButtons dropdown-toggle" type="button" data-toggle="dropdown" style="display: flex; position: relative;">

          <span *ngIf="myUser?.filenameLogo" class="avatarTableFlat">
            <div>
              <img class="companyLogo" src="{{GlobalSettings.CDNUrl + '/' + myUser.filenameLogo}}">
            </div>
          </span>
          <span *ngIf="!myUser?.filenameLogo" class="fal fa-user" style="margin-top: 12px; margin-right: 10px;"></span>
          <div style="margin-top: 10px;">{{username | removeAzure}}{{usernameRole}} <i class="fal fa-angle-down" aria-hidden="true"></i></div>
        </button>

        <div class="dropdown-menu" style="width: 330px; padding: 15px;">

    <!-------------------------------------------------Change User-------------------------------------------->
          <ng-container *ngIf="showSelectChild">
            <div class="row">
              <button class="otp-btn default dropdown_el" (click)="showModalChangeUser()"><i class="fal fa-users-class icon-choice-nav blueColor"></i> {{'NAVBAR.ADMIN_LOGIN' | translate}}</button>
            </div>
          </ng-container>
          <ng-container *ngIf="myUser?.availableSwitchUsers && myUser?.availableSwitchUsers.length > 0">
            <div *ngFor="let otherUser of myUser.availableSwitchUsers" class="row">
              <button class="otp-btn default dropdown_el" (click)="switchUser(otherUser.value)"><span class="fal fa-user icon-choice-nav blueColor"></span> {{otherUser.label}}</button>
            </div>
          </ng-container>

    <!-------------------------------------------------User Profile------------------------------------------->
          <div class="row">
            <a [routerLink]="'/' + Constants.LINK_MANAGER_PART + '/' + Constants.LINK_ACCOUNT_DETAILS"><button class="otp-btn default dropdown_el"> <i class="fal fa-user-cog icon-choice-nav bBlue"></i> {{'USERS.MY_ACCOUNT' | translate}} <span class="adminColor"> {{usernameRole}}</span></button></a>
            <a [routerLink]="'/' + Constants.LINK_MANAGER_PART + '/' + Constants.LINK_PAYMENT_PROFILE" *ngIf="isPublisher()"><button class="otp-btn default dropdown_el"> <i class="fal fa-file-invoice-dollar icon-choice-nav yellowColor"></i> {{'USERS.PAYMENT_PROFILE' | translate}} <span class="adminColor"> {{usernameRole}}</span></button></a>
          </div>


          <div class="row" *ngIf="canGoBack()">
            <button class="otp-btn default dropdown_el" (click)="returnFather()"><i class="fal fa-user-crown icon-choice-nav purpleColor"></i> {{'NAVBAR.RETURN' | translate}}</button>
          </div>

    <!------------------------------------------------Logout button------------------------------------------->
          <div class="row">
            <button class="otp-btn default dropdown_el" (click)="logout()"><span class="fal fa-power-off icon-choice-nav redColor"></span> Logout</button>
          </div>

        </div>

      </li>
      <!--<app-agreementpub></app-agreementpub>-->
      <!--Notifiche-->
      <li class="dropdown" *ngIf="isAdmin()" style="padding-top: 12px;">
        <button data-toggle="dropdown" class="otp-btn default navButtons dropdown-toggle">
          <span *ngIf="getNotifyUnread() === 0" class="fal fa-bell"></span>
          <span *ngIf="notifications && getNotifyUnread() > 0" class="bell fal fa-bell"></span>
          <label *ngIf="notifications && getNotifyUnread() > 0" class="redColor numNotif">{{getNotifyUnread()}}</label>
        </button>
        <ul class="dropdown-menu" style="overflow-x: hidden; max-height: 370px; overflow-y: auto; margin-top: 5px">
          <div style="width: 450px; height: 20px; margin-top: 20px; border-bottom: solid 1px #999">
            <a style="cursor: pointer; margin-left: 20px;" (click)="openBlacklist()">Exclusion list</a>
          </div>
          <li *ngIf="notifications && notifications.length === 0" style="width: 450px">
            <button class="otp-btn default dropdown_el"> <i class="fal fa-sticky-note"></i> Nessuna notifica!</button>
          </li>
          <li *ngFor="let notif of notifications" style="border: none; padding: 5px; width: 450px;" >
            <i *ngIf="isCampaignNotify(notif.type)" class="crossOnNotif fal fa-times" [pTooltip]="'Non mostrare più notifiche relative a questa campagna'" (click)="hideNotif(notif.campaign)"></i>
            <button [class]="'otp-btn default notific-button dropdown_el ' + ifIsUnread(notif.read)" (click)="readNotify($event)">
              <div class="notif-username">{{notif.username}}</div>
              <div style="margin: 5px 0 0;" class="row">
                <div class="col-xs-2">
                  <div *ngIf="notif.read === 0">
                    <i class="redColor">New!</i>
                  </div>
                  <div style="margin-top: 10px">
                    <i *ngIf="notif.type === NOTIFY_BUYER_CAMP" class="fas fa-circle fa-3x buyerNotif" aria-hidden="true" [pTooltip]="'Buyer Campaign Live'"></i>
                    <i *ngIf="notif.type === NOTIFY_PUBLISHER_CAMP" class="fas fa-circle fa-3x publisherNotif" aria-hidden="true" [pTooltip]="'Publisher Campaign Live'"></i>
                    <i *ngIf="notif.type === NOTIFY_STATUS_CAMP" class="fal fa-exchange-alt fa-3x statusNotif" aria-hidden="true" [pTooltip]="'Campaign Status Changed'"></i>
                    <i *ngIf="notif.type === NOTIFY_ALERT_CAMP" class="fal fa-exclamation-triangle fa-3x alertNotif" aria-hidden="true" [pTooltip]="'Metric Alert'"></i>
                    <i *ngIf="notif.type === NOTIFY_BILL" class="fab fa-telegram-plane fa-3x billingNotif" aria-hidden="true" [pTooltip]="'Invoice Loaded'"></i>
                    <i *ngIf="notif.type === NOTIFY_DEMAND_PARTENERS" class="fas fa-upload fa-2x statusNotif" aria-hidden="true" [pTooltip]="'Demand Partner Updated'"></i>
                    <i *ngIf="notif.type === NOTIFY_ALERT_DEAL" class="fas fa-handshake fa-2x statusNotif" aria-hidden="true" [pTooltip]="'Deal Status Changed'"></i>
                  </div>
                </div>
                <div class="col-xs-9 userNotify">
                  <div [innerHTML]="getColored(notif.text)"></div>
                  <div class="timeNotification">
                    <span class="blueColor">{{ getNotifyTimeLabel(notif.timestamp) }}</span>
                    - <span class="blueColor">{{UtilsFun.getOnlyHour(notif.timestamp)}}</span>
                  </div>
                </div>
              </div>
            </button>
          </li>
        </ul>
      </li>

      <li *ngIf="canGoBack()" (click)="returnFather()" class="navButtons passUser" [pTooltip]="'NAVBAR.RETURN' | translate" tooltipPosition="left">
        <i class="fal fa-arrow-left" ></i>
      </li>
    </ul>

<!--------------------------------------------------- Mobile Version -------------------------------------------------->

    <a [routerLink]="'/' + Constants.LINK_HOME" (click)="goTo(Constants.LINK_HOME)" >
      <img class="otlogo w3-hide-large" [src]="mobileImg"/>
    </a>

    <!-- secondoMenu-->
    <div class="w3-hide-large secondMenuMobileContainer" *ngIf="!loading && areThereItems() && url !== '/home'">
      <li>
        <span class="sidebarElementNavbarMobile"> <label class="choicesNavMobile"> <i class="fal fa-chevron-right"></i></label> </span>
      </li>
      <ng-container *ngFor="let item of getElementMenu().items;">
        <ul *ngIf="(url | containsUrl: item :myElements)" class="nav nav-pills secondMenuMobile">
          <ng-container *ngFor="let it of item.subItems">
            <li *ngIf="it.link !== LINK_EXCLUDE">
              <a class="sidebarElementNavbarMobile" [ngClass]="{'sidebarElementNavbarTest': !prodOrTest}" routerLink="{{goToMenu(it)}}" [class.selectedTabNavBarMobile]="isTheUrl(url, it.link)">
                <label class="choicesNavMobile">
                  <ng-container *ngIf="isModCamp() && isTheUrl(url, it.link)">{{'NEW_CAMPAIGN.EDIT_CAMPAIGN' | translate}}</ng-container>
                  <ng-container *ngIf="!(isModCamp() && isTheUrl(url, it.link))">{{it.label}}</ng-container>
                </label>
              </a>
            </li>
          </ng-container>
        </ul>
      </ng-container>
      <ul *ngIf="(url | containsUrl: {subItems: myUserProfileList} :myElements)" class="nav nav-pills secondMenuMobile">
        <ng-container *ngFor="let it of myUserProfileList">
          <li *ngIf="(JwtPermissions.admin || !it.beta) && (isPublisher() || !it.fixed)">
            <a class="sidebarElementNavbarMobile" [ngClass]="{'sidebarElementNavbarTest': !prodOrTest}" routerLink="{{goToMenu(it)}}" [class.selectedTabNavBarMobile]="isTheUrl(url, it.link)">
              <label class="choicesNavMobile">{{it.label}}</label>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>

    <!--Notifiche mobile-->
    <span class="dropdown w3-hide-large bellMobile" *ngIf="isAdmin()">
      <button data-toggle="dropdown" class="otp-btn default navButtons dropdown-toggle">
        <span *ngIf="notifications && getNotifyUnread() == 0" class="fal fa-bell" style="font-size: 20pt"></span>
        <span *ngIf="notifications && getNotifyUnread() > 0" class="bell fal fa-bell" style="font-size: 20pt"></span>
        <label *ngIf="notifications && getNotifyUnread() > 0" class="redColor numNotif" style="top: 2px; right: 0px;">{{getNotifyUnread()}}</label>
      </button>
      <ul class="dropdown-menu" style="position: fixed; left: 10%; width: 90%; top: 62px; overflow-x: hidden; max-height: 85vh; overflow-y: auto;">
        <li *ngIf="notifications && notifications.length === 0">
          <button class="otp-btn default dropdown_el">Nessuna notifica!</button>
        </li>
        <li *ngFor="let notif of notifications" style="border: none; padding: 5px;" >
          <button  [class]="'btn default notific-button dropdown_el ' + ifIsUnread(notif.read)" (click)="readNotify($event)">
            <div class="notif-username">{{notif.username}}</div>
            <div class="row">
              <div class="col-xs-2">
                <div *ngIf="notif.read === 0">
                  <i class="redColor">New!</i>
                </div>
                <div style="margin-top: 10px">
                  <i *ngIf="notif.type === 1" class="fas fa-circle fa-2x buyerNotif" aria-hidden="true" [pTooltip]="'Campagna Buyer Live'"></i>
                  <i *ngIf="notif.type === 2" class="fas fa-circle fa-2x publisherNotif" aria-hidden="true" [pTooltip]="'Campagna Publisher Live'"></i>
                  <i *ngIf="notif.type === 3" class="fal fa-exchange-alt fa-2x statusNotif" aria-hidden="true" [pTooltip]="'Stato campagna cambiato'"></i>
                  <i *ngIf="notif.type === 4" class="fal fa-exclamation-triangle fa-2x alertNotif" aria-hidden="true" [pTooltip]="'Metric Alert'"></i>
                  <i *ngIf="notif.type === 5" class="fab fa-telegram-plane fa-2x billingNotif" aria-hidden="true" [pTooltip]="'Fattura caricata'"></i>
                  <i *ngIf="notif.type === 6" class="fas fa-user-friends fa-2x statusNotif" aria-hidden="true" [pTooltip]="'Sync facebook'"></i>
                  <i *ngIf="notif.type === 7" class="fas fa-user-friends fa-2x alertNotif" aria-hidden="true" [pTooltip]="'Errore generico facebook'"></i>
                </div>
              </div>

              <div class="col-xs-10 userNotify">
                <div [innerHTML]="getColored(notif.text)"></div>
                <div class="timeNotification">
                  <span class="blueColor">{{ getNotifyTimeLabel(notif.timestamp) }}</span>
                  - <span class="blueColor">{{UtilsFun.getOnlyHour(notif.timestamp)}}</span>
                </div>
              </div>
            </div>
          </button>
        </li>
      </ul>
    </span>

    <span class="dropdown userMobile">
      <span id="menu_control" class="w3-hide-large fal fa-user-circle dropdown-toggle menu_control" data-toggle="dropdown"> </span>
      <ul id="menu_control_content" class="dropdown-menu" style="position: fixed; left: 10%; width: 90%; top: 62px;">
        <!--Cambia utente-->
        <li class="mobileControlMenu"><a [routerLink]="'/' + Constants.LINK_MANAGER_PART + '/' + Constants.LINK_ACCOUNT_DETAILS" style="padding: 6px 0px; border-bottom: solid 1px #ddd;"><button class="otp-btn default navButtons mobileControlMenu">{{username | removeAzure}}{{usernameRole}}</button></a></li>
        <li class="mobileControlMenu"><a [routerLink]="'/' + Constants.LINK_MANAGER_PART + '/' + Constants.LINK_PAYMENT_PROFILE" *ngIf="isPublisher()" style="padding: 6px 0px; border-bottom: solid 1px #ddd;"><button class="otp-btn default navButtons mobileControlMenu">{{'USERS.PAYMENT_PROFILE' | translate}} <span class="adminColor"> {{usernameRole}}</span></button></a></li>
        <li *ngIf="showSelectChild" class="mobileControlMenu"><button class="otp-btn default navButtons mobileControlMenu" (click)="showModalChangeUser()">{{'NAVBAR.CHANGE' | translate}}</button></li>
        <li *ngIf="canGoBack()" class="mobileControlMenu"><button class="otp-btn default navButtons mobileControlMenu" (click)="returnFather()">{{'NAVBAR.RETURN' | translate}}</button></li>
        <!--Logout-->
        <li class="mobileControlMenu" (click)="logout()"><button class="otp-btn default navButtons mobileControlMenu">Logout</button></li>
      </ul>
    </span>
  </div>

  <!-- secondoMenu-->
  <div class="tabs w3-hide-small w3-hide-medium" *ngIf="!loading && areThereItems()">
    <ng-container *ngFor="let item of getElementMenu().items;">
      <ul *ngIf="(url | containsUrl: item :myElements)" class="nav nav-pills secondMenu">
        <ng-container *ngFor="let it of item.subItems">
          <li *ngIf="it.link !== LINK_EXCLUDE" style="height: 25px">
            <a class="sidebarElementNavbar" [ngClass]="{'sidebarElementNavbarTest': !prodOrTest}" routerLink="{{goToMenu(it)}}" [class.selectedTabNavBar]="isTheUrl(url, it.link)">
              <label class="choicesNav">
                <ng-container *ngIf="isModCamp() && isTheUrl(url, it.link)">{{'NEW_CAMPAIGN.EDIT_CAMPAIGN' | translate}}</ng-container>
                <ng-container *ngIf="!(isModCamp() && isTheUrl(url, it.link))">{{it.label}} &nbsp;</ng-container>
              </label>
            </a>
          </li>
        </ng-container>
      </ul>
    </ng-container>
    <ul *ngIf="(url | containsUrl: {subItems: myUserProfileList} :myElements)" class="nav nav-pills secondMenu">
      <ng-container *ngFor="let it of myUserProfileList">
        <li *ngIf="JwtPermissions.admin || !it.beta" style="height: 25px">
          <a class="sidebarElementNavbar" [ngClass]="{'sidebarElementNavbarTest': !prodOrTest}" routerLink="{{goToMenu(it)}}" [class.selectedTabNavBar]="isTheUrl(url, it.link)">
            <label class="choicesNav">{{it.label}}</label>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="notification-panel">
    <app-communication-bar *ngIf="myUser" [myBarList]="myUser.communicationInfo" [entityInfo]="entityInfo"></app-communication-bar>
  </div>
</nav>


<app-notify *ngIf="isAdmin()" (notifications)="pushNotification($event)"></app-notify>

<app-sidepanelstack #stackNavbar></app-sidepanelstack>

<!------------------------------------------------------------- MODALE CAMBIA UTENTE --------------------------------------------------------->

<app-sidepanel #changeUserModal>
  <div class="closeModalIcon" (click)="stackNavbar.pop()"> <i class=" fal fa-times"></i> </div>
  <div *ngIf="changeUserModal.isVisible">
    <div class="standardLessContainerModal">
      <p class="side-modal-description">
        <i class="fal fa-user-circle"></i> {{'NAVBAR.LIST' | translate}}
      </p>
      <div class="modalSidebar">
        <app-groupsidebar [stack]="stack" [isSubmitDisabled]="!userSelected" [label]="'Ok'" (submit)="userChange()"></app-groupsidebar>
      </div>
      <div class="modalContainerWithSidebar smallerSidebar">
        <div class="modal-under-title">
          <p> {{'GLOBAL.SELECT_ACCOUNT' | translate}} </p>

          <!-- User recenti-->
          <div *ngIf="recentUsers && recentUsers.length>0" class="listRecentUser list-group-item" style="background-color: #fff; color: #0068FA; cursor: default; padding: 5px; font-weight: bold;"><i class="fal fa-clock" aria-hidden="true"></i> Utenti recenti</div>
          <div *ngFor="let recUser of recentUsers" (click)="userSelected = recUser.value" [class]="'listRecentUser list-group-item ' + getClassSelected(recUser.value)">
            <span *ngIf="UtilsFun.isInEntityTypes(recUser.type)" style="vertical-align:middle;" [style.color]="UtilsFun.findEntityType(recUser.type).color"><i class="fal fa-thumbtack" aria-hidden="true"></i> {{UtilsFun.findEntityType(recUser.type).name}} -  </span>
            <span style="vertical-align:middle"> {{recUser.label}}</span>
          </div>

          <div class="liSearchNav">
            <span *ngIf="isAdmin()" class="dropdown" style="text-align: left; padding:0;">
              <button class="dropdown-toggle"  data-toggle="dropdown" style="background-color: transparent; padding-left: 0; padding-right: 0;">
                <span *ngIf="UtilsFun.isInEntityTypes(typeUserFiltered)" style="padding: 5px; cursor: pointer;" [style.color]="UtilsFun.findEntityType(typeUserFiltered).color"><i class="fal fa-filter" aria-hidden="true"></i> {{UtilsFun.findEntityType(typeUserFiltered).name}}
                </span>
                <i class="fal fa-angle-down"></i>
              </button>
              <ul class="dropdown-menu" style="height: 230px; overflow-y: auto; min-width: 220px; margin-top: 5px">
                <li *ngFor="let elem of Constants.ENTITIES" class="listRecentUser" style="padding: 5px; cursor: pointer;" [style.color]="elem.color" (click)="typeUserFiltered = elem.id; getOneTypeUsers()"><i class="fal fa-filter" aria-hidden="true"></i> {{elem.name}}
                </li>
              </ul>
            </span>
          </div>


          <div class="containerListUSer">
            <div>
              <p-listbox id="listbox" [options]="usersFiltered" [(ngModel)]="userSelected" [style]="{'width':'100%'}" [listStyle]="{'max-height':'240px'}" [filter]="true" (keydown)="onSelectUser($event)">
                <ng-template let-user pTemplate="item">
                  <div class="ui-helper-clearfix" tabindex="-1" >
                    <span *ngIf="UtilsFun.isInEntityTypes(user.type)" style="vertical-align:middle;" [style.color]="UtilsFun.findEntityType(user.type).color"> {{UtilsFun.findEntityType(user.type).name}} -  </span>
                    <span style="vertical-align:middle"> {{user.label}}</span>
                  </div>
                </ng-template>
              </p-listbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-sidepanel>

<!--<p-dialog *ngIf="displayChangeUserModal" [(visible)]="displayChangeUserModal" [modal]="true" [blockScroll]="true" [style]="{width: getWidthModal() + 'px'}" [responsive]="true">-->
<!--  <p-header>-->
<!--    <label class="title_dialog"><i class="fal fa-user-circle"></i> {{'NAVBAR.LIST' | translate}}</label>-->
<!--  </p-header>-->
<!--  <div class="dialog-body" style="padding: 10px 10px 20px; min-height: 500px;">-->
<!--    <p> {{'GLOBAL.SELECT_ACCOUNT' | translate}} </p>-->

<!--    &lt;!&ndash; User recenti&ndash;&gt;-->
<!--    <div *ngIf="recentUsers && recentUsers.length>0" class="listRecentUser list-group-item" style="background-color: #fff; color: #0068FA; cursor: default; padding: 5px; font-weight: bold;"><i class="fal fa-clock" aria-hidden="true"></i> Utenti recenti</div>-->
<!--    <div *ngFor="let recUser of recentUsers" (click)="userSelected = recUser.value" [class]="'listRecentUser list-group-item ' + getClassSelected(recUser.value)">-->
<!--      <span *ngIf="UtilsFun.isInEntityTypes(recUser.type)" style="vertical-align:middle;" [style.color]="UtilsFun.findEntityType(recUser.type).color"><i class="fal fa-thumbtack" aria-hidden="true"></i> {{UtilsFun.findEntityType(recUser.type).name}} -  </span>-->
<!--      <span style="vertical-align:middle"> {{recUser.label}}</span>-->
<!--    </div>-->

<!--    <div class="liSearchNav">-->
<!--      <span *ngIf="isAdmin()" class="dropdown" style="text-align: left; padding:0;">-->
<!--        <button class="dropdown-toggle"  data-toggle="dropdown" style="background-color: transparent; padding-left: 0; padding-right: 0;">-->
<!--          <span *ngIf="UtilsFun.isInEntityTypes(typeUserFiltered)" style="padding: 5px; cursor: pointer;" [style.color]="UtilsFun.findEntityType(typeUserFiltered).color"><i class="fal fa-filter" aria-hidden="true"></i> {{UtilsFun.findEntityType(typeUserFiltered).name}}-->
<!--          </span>-->
<!--          <i class="fal fa-angle-down"></i>-->
<!--        </button>-->
<!--        <ul class="dropdown-menu" style="height: 230px; overflow-y: auto; min-width: 220px; margin-top: 5px">-->
<!--          <li *ngFor="let elem of Constants.ENTITIES" class="listRecentUser" style="padding: 5px; cursor: pointer;" [style.color]="elem.color" (click)="typeUserFiltered = elem.id; getOneTypeUsers()"><i class="fal fa-filter" aria-hidden="true"></i> {{elem.name}}-->
<!--          </li>-->
<!--        </ul>-->
<!--      </span>-->
<!--    </div>-->


<!--    <div class="containerListUSer">-->
<!--      <div>-->
<!--        <p-listbox id="listbox" [options]="usersFiltered" [(ngModel)]="userSelected" [style]="{'width':'100%'}" [listStyle]="{'max-height':'240px'}" [filter]="true" (keydown)="onSelectUser($event)">-->
<!--          <ng-template let-user pTemplate="item">-->
<!--            <div class="ui-helper-clearfix" tabindex="-1" >-->
<!--              <span *ngIf="UtilsFun.isInEntityTypes(user.type)" style="vertical-align:middle;" [style.color]="UtilsFun.findEntityType(user.type).color"> {{UtilsFun.findEntityType(user.type).name}} -  </span>-->
<!--              <span style="vertical-align:middle"> {{user.label}}</span>-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </p-listbox>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <p-footer>-->
<!--    <button class="otp-btn success" (click)="userChange(); displayChangeUserModal=false" [disabled]="!userSelected"><i class="fal fa-check"></i> Ok</button>-->
<!--    <button class="otp-btn danger" (click)="displayChangeUserModal=false"><i class="fal fa-times"></i> {{'GLOBAL.CANCEL' | translate}}</button>-->
<!--  </p-footer>-->
<!--</p-dialog>-->

<!--------------------------------------------  Modale Exclusion list ----------------------------------------------------->
<p-dialog [(visible)]="displayBlacklist" [modal]="true" [style]="{width: getWidthModal() + 'px'}" [resizable]="false">
  <p-header>
    <label class="title_dialog"><i class="fal fa-times"></i> Exclusion list</label>
  </p-header>
  <div class="tableModalSty" style="overflow-y: auto; height: 100%; min-height: 600px;">
    <ng-container *ngIf="blackList && blackList.length > 0">
      <div *ngFor="let elem of blackList" style="border: solid 1px #999">
        <div class="row" style="padding: 5px">
          <div class="col-sm-3" [pTooltip]="elem.entity_name">{{elem.entity_name}}</div>
          <div class="col-sm-7" [pTooltip]="elem.campaign_name + ' (' + elem.id_campaign + ')'">{{elem.campaign_name}} ({{elem.id_campaign}})</div>
          <div class="col-sm-2"><button class="btn-action primary" (click)="restoreCampaign(elem.id_campaign)" [pTooltip]="'GLOBAL.RESTORE' | translate"><i class="fal fa-trash-restore-alt"></i></button> </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!(blackList && blackList.length > 0)">
      <div style="color: #40dc7e">Nessuna campagna in exclusion list <i class="fal fa-check"></i></div>
    </ng-container>

  </div>
  <p-footer>
    <button class="btn-action default" (click)="displayBlacklist=false"><i class="fal fa-times"></i> Ok</button>
  </p-footer>
</p-dialog>


<!-- adblock control -->
<div id="adBlockID" class="adsbox" style="height: 1px;">
  <ng-container *ngIf="adBlockChecked">
    {{getAdBlockAdvisor()}}
  </ng-container>
</div>

<!-- disabilitare adblock -->
<div id="modalBlock" class="modal fade in" role="dialog" style="opacity: 0; background-color: #0000004a;">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <label class="title_dialog"><i class="fal fa-user-circle"></i> {{'NAVBAR.DISABLE' | translate}} ADBLOCK</label>
      </div>
      <div class="modal-body">
        <img src="../../../assets/imgs/anti.png" style="height: 100px; width: auto; margin-left: 25%;"/>
        <p>{{'NAVBAR.DISABLE_MSG' | translate}}</p>
      </div>
      <div class="modal-footer">
        <button class="otp-btn success"  data-dismiss="modal" (click)="closeAdBlockAdvisor()"><i class="fal fa-times"></i> OK </button>
      </div>
    </div>
  </div>
</div>

<!-------------------------------------------------Modal "Sei sicuro?"------------------------------------------------->

<p-dialog [(visible)]="displayRuSure" [style]="{width: 350 + 'px'}" [modal]="true">
  <p-header>
    <label class="title_dialog">  {{'GLOBAL.CONFIRM' | translate}} </label>
  </p-header>
  <div class="dialog-body" style="text-align: center">
    {{'GLOBAL.R_U_SURE' | translate}}
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button class="otp-btn success" (click)="stopNotif()"><i class="fal fa-check"></i>{{'GLOBAL.YES' | translate}}</button>
      <button class="otp-btn danger" (click)="displayRuSure = false"><i class="fal fa-times"></i> No</button>
    </div>
  </p-footer>
</p-dialog>

<app-loadingpage [isHeavy]="true" *ngIf="loading"></app-loadingpage>


