import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {InfoService} from '../../services/info.service';
import {NavigationEnd, Router} from '@angular/router';
import {MenuElements} from '../../__helpers/entities/MenuElement';
import {LoginService} from '../../services/login.service';
import {MessageService, SelectItem} from 'primeng/api';
import {PageService} from '../../services/page.service';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {Constants} from '../../__helpers/entities/Constants';
import {environment} from '../../../environments/environment';
import {TableService} from '../../services/table.service';
import {ResponseInfo} from '../../__helpers/entities/ResponseInfo';
import {UtilsFun} from '../../__helpers/entities/UtilsFun';
import {NotificationData} from '../../__helpers/entities/NotificationData';
import {PageContent} from '../../__helpers/entities/PageContent';
import {BLCampaignEntryData} from '../../__helpers/entities/BLCampaignEntryData';
import {SegmentTypes} from '../../__helpers/entities/SegmentTypes';
import {JwtPermissions} from '../../__helpers/jwtPermissions.service';
import { GlobalSettings } from 'GlobalSettings';
import {EntityService} from '../../services/entityService';
import {EntityAccount} from '../../__helpers/entities/EntityAccount';
import {UserAs} from '../../__helpers/UserAs';
import { Subscription } from 'rxjs';
import {SidePanelStackComponent} from '../sidePanel/sidePanelStack/sidePanelStack.component';
import {SidePanelComponent} from '../sidePanel/sidePanel.component';
import {AppConfigService} from "../../__helpers/appConfig/appConfig.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit, OnDestroy {

  @Input() shortView?: boolean;
  @ViewChild('stackNavbar', { static: true }) stack: SidePanelStackComponent;
  @ViewChild('changeUserModal', {static: true}) public changeUserModal: SidePanelComponent;

  timezones: SelectItem[];
  displayChangeUserModal = false;
  displayRuSure: boolean;
  displayBlacklist: boolean;
  userSelected: number;
  username: string;
  usernameRole: string;
  loading = false;
  showSelectChild: boolean;
  showReturnFather: boolean;
  prodOrTest = environment.production;
  sandbox: boolean;

  hideExpand = false;
  actualIdToHide: number;

  selectedPage: MenuElements = null;
  translate: TranslateService;

  notifications: NotificationData[];
  blackList: BLCampaignEntryData[] = [];

  // user filter
  usersFiltered: any[];
  typeUserFiltered = 0;
  recentUsers = [];

  adBlockChecked = false;

  mobileImg: string;

  url: string;

  LINK_EXCLUDE = Constants.LINK_NEW_CAMPAIGN_CERBERUS_BUYER_PART + '/' + Constants.LINK_CAMPAIGN_CERBERUS;

  entityInfo: EntityAccount;

  readonly ACCOUNT_DETAILS_ID = 1001;
  readonly COMPANY_DETAILS_ID = 1002;
  readonly ADMIN_DETAILS_ID = 1003;
  readonly PAYMENT_PROFILE_ID = 1004;
  readonly BILLING_DETAILS_ID = 1005;

  readonly LIST_NO_LOTTOMATICA = [Constants.MODA_ONETAG_MAIL, Constants.BARRETTA_ONETAG_MAIL];

  allProfileList: MenuElements[] =
    [
      {id: this.ACCOUNT_DETAILS_ID, label: 'Account details', link: Constants.LINK_MANAGER_PART + '/' + Constants.LINK_ACCOUNT_DETAILS},
      {id: this.COMPANY_DETAILS_ID, label: 'Company details', link: Constants.LINK_MANAGER_PART + '/' + Constants.LINK_COMPANY_DETAILS},
      {id: this.ADMIN_DETAILS_ID, label: 'Admin zone', link: Constants.LINK_MANAGER_PART + '/' + Constants.LINK_ADMIN_ZONE, value: Constants.ADMIN},
      {id: this.BILLING_DETAILS_ID, label: 'Billing settings', link: Constants.LINK_MANAGER_PART + '/' + Constants.LINK_BILLING_ADMIN_ZONE, value: -Constants.ADMIN},
      {id: this.PAYMENT_PROFILE_ID, label: 'Payment profile', link: Constants.LINK_MANAGER_PART + '/' + Constants.LINK_PAYMENT_PROFILE, value: Constants.PUBLISHER},
    ];

  myUserProfileList: MenuElements[] = [];

  subscription: Subscription;

  TableService = TableService;
  Constants = Constants;
  UtilsFun = UtilsFun;
  GlobalSettings = GlobalSettings;
  JwtPermissions = JwtPermissions;

  readonly NOTIFY_BUYER_CAMP = 1;
  readonly NOTIFY_PUBLISHER_CAMP = 2;
  readonly NOTIFY_STATUS_CAMP = 3;
  readonly NOTIFY_ALERT_CAMP = 4;
  readonly NOTIFY_BILL = 5;
  readonly NOTIFY_DEMAND_PARTENERS = 8;
  readonly NOTIFY_ALERT_DEAL = 9;

  constructor(private router: Router, private pageService: PageService, private titleService: Title, private tableService: TableService,
              private infoService: InfoService, private loginService: LoginService, private messageService: MessageService,
              private entityService: EntityService, private appConfig: AppConfigService) {

    router.events.subscribe((val) => {
      const a: NavigationEnd = val as NavigationEnd;
      this.hideExpand = a.url === '/' + Constants.LINK_NEW_CAMPAIGN;
      this.compress();
      if ( val instanceof NavigationEnd) {
        this.url = val.url;
      }
    });
    try {
      this.infoService.changeUserOp(undefined);
      this.subscription = this.infoService.currentUserChange.subscribe(res => {
        if (res) {
          this.identifyResult(res)
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  ngOnInit(): void {
    this.appConfig.appConfigPolling(); // TODO: possibile rimuovere in futuro su richiesta
    this.prodOrTest = environment.production && !UtilsFun.matchDestinationDev();
    this.sandbox = UtilsFun.matchDestinationSandbox();

    this.infoService.getConditionChangesInObs().subscribe(next => {
      this.getEntityInfo();
    });

    this.loadLogo();
    this.translate = this.infoService.translate;
    this.url = this.router.url;
    this.router.events.subscribe((event) => { // setta i titoli quando viene cambiata pagina
      if (event instanceof NavigationEnd) {
        this.infoService.getElementsGettedInObs().subscribe(() => {
          const url = this.router.url;
          this.selectedPage = this.findActualPage(url);
          if (this.selectedPage === undefined) {
            this.router.navigate(['/' + Constants.LINK_HOME]);
          }
          if (this.UtilsFun.matchDestinationCarat()) {
            this.titleService.setTitle('DAN ');
          } else {
            if (this.selectedPage !== undefined) {
              if (this.selectedPage === null) {
                this.titleService.setTitle('Onetag Platform');
              } else {
                this.titleService.setTitle(this.selectedPage.label + ' - Onetag Platform');
              }
            } else {
              this.titleService.setTitle('Onetag Platform');
            }
          }

          // tracking quando cambio pagina
          if (this.infoService.user && JwtPermissions.getCurrentTokenID()) {
            if (document.getElementById('ot_track')) {
              const element = document.getElementById('ot_track');
              element.parentNode.removeChild(element);
            }
            this.trackUser();
          }
        });
      }
    });
    this.getNotifications();

    if (!this.infoService.user) {
      this.getUser(true);
    } else if (this.infoService.elements) {
      this.elementLoader(this.infoService.elements, false);
      this.username = this.infoService.user.username;
      this.trackUser();
    } else {
      this.getElement(1);
      this.username = this.infoService.user.username;
      this.trackUser();
    }
  }

  private getEntityInfo() {
    if (this.isPublisher()) {
      this.entityService.getEntityDetail(this.infoService.user.entityId).subscribe(responseEntity => {
        this.entityInfo = responseEntity;
      });
    }
  }

  trackUser() {
    // pixel tracking
    if (JwtPermissions.getCurrentTokenID() && !UserAs.userAsID && !JwtPermissions.admin && environment.production) {
      const a = document.createElement('script');
      if (a && this.infoService.user) {
        a.id = 'ot_track';
        a.async = true;
        if (this.infoService.user.id === 642 || this.infoService.user.id === 547 || this.infoService.user.id === 66) { // Fontana pixel
          a.src = 'https://onetag-sys.com/sync/a,1023/2';
        } else if (this.infoService.user.id === 709) { // Garofalo pixel
          a.src = 'https://onetag-sys.com/sync/a,1892/2';
        } else if (this.infoService.user.type === this.Constants.BUYER) {
          a.src = 'https://onetag-sys.com/sync/a,1024/2';
        } else if (this.infoService.user.type === this.Constants.PUBLISHER) {
          a.src = 'https://onetag-sys.com/sync/a,1025/2';
        }
      }
      const h = document.getElementsByTagName('head');
      if (h && h.length > 0) {
        h[0].appendChild(a);
      }
    }
  }

  isModCamp(): boolean {
    return (this.router.url && this.router.url.indexOf( Constants.LINK_NEW_CAMPAIGN + '?') > 0);
  }

  getElementMenu(): PageContent {
    return this.infoService.elements;
  }

  loadLogo() {
    this.mobileImg = '../../assets/imgs/onetag_logo_arrow_white.png';
    this.infoService.getUserGettedInObs().subscribe(() => {
      if (this.infoService.user.entityId === Constants.PUBLICIS_MEDIA_BUYER_ENTITY_ID || this.infoService.user.entityId === Constants.PUBLICIS_MEDIA_PUBLISHER_ENTITY_ID) {
        this.mobileImg = '../../assets/imgs/logo_publicis_white.png';
      }
    });
  }

  goToMenu (item: MenuElements) {
    return'./' + item.link;
  }

  isTheUrl(url, link: string): boolean {
    if (url) {
      const baseUrl = url.split('?')[0];
      if (baseUrl) {
        if ('/' + link === baseUrl) {
          return true;
        }
      }
    }
    return false;
  }

  isInNewCampaign(url): boolean {
    if (url) {
      const baseUrl = url.split('?')[0];
      if (baseUrl) {
        if ('/' + Constants.LINK_NEW_CAMPAIGN === baseUrl) {
          return true;
        }
      }
    }
    return false;
  }

  getNotifications() {
    if (this.isAdmin()) {
      this.infoService.getNotifications().subscribe(res => {
        this.notifications = res;
      });
    }
  }

  readNotify(event) {
    if (this.notifications && this.notifications.length > 0 && this.notifications.some(elem => elem.read === 0)) {
      this.infoService.setReadNotifications(this.notifications[0].id).subscribe(() => {
        this.notifications = this.notifications.map(elem => { elem.read = 1; return elem});
      });
    }
  }

  getNotifyUnread(): number {
    if (this.notifications && this.notifications.length > 0) {
      return this.notifications.filter(elem => elem.read === 0).length;
    }
    return 0;
  }

  hideNotif(idCampaign: number) {
    this.actualIdToHide = idCampaign;
    this.displayRuSure = true;
  }

  stopNotif() {
    this.infoService.addCampaignToBlacklist(this.actualIdToHide).subscribe(() => {
      this.actualIdToHide = undefined;
      this.loading = false;
      this.displayRuSure = false;
      this.getNotifications();
    }, (err) => { this.loading = false; });
  }

  pushNotification(event) {
    if (this.notifications) {
      const temp = [JSON.parse(event) as NotificationData];
      temp.push(...this.notifications);
      this.notifications = temp;
    }
  }

  isPublisher() {
    return this.infoService.isPublisher();
  }

  findActualPage(url: string): MenuElements {
    if (this.infoService.elements && this.infoService.elements.items && this.infoService.elements.items.length > 0) {
      for (const el of this.infoService.elements.items) {
        if (el.subItems && el.subItems.length > 0) {
          for (const el2 of el.subItems) {
            if (url === ('/' + el2.link) || url.indexOf('/' + el2.link + '?') !== -1) {
              return el2;
            }
          }
        }
      }
    }
    switch (url) {
      case '/' + Constants.LINK_HOME:
      case '/' + Constants.LINK_MANAGER_PART + '/' + Constants.LINK_ACCOUNT_DETAILS :
      case '/' + Constants.LINK_MANAGER_PART + '/' + Constants.LINK_COMPANY_DETAILS : {
        return null;
      }
      case '/' + Constants.LINK_MANAGER_PART + '/' + Constants.LINK_PAYMENT_PROFILE : {
        return this.isPublisher() ? null : undefined;
      }
      case '/' + Constants.LINK_MANAGER_PART + '/' + Constants.LINK_BILLING_ADMIN_ZONE :
      case '/' + Constants.LINK_MANAGER_PART + '/' + Constants.LINK_ADMIN_ZONE : {
        if (JwtPermissions.admin) {
          return null;
        }
        return undefined;
      }
    }
  }

// Logout
  logout(): void {
    this.selectedPage = null;
    this.titleService.setTitle('Onetag Platform');
    this.loginService.logout();
  }

  // link alla pagina selezionata
  goTo(link: string) {
    // this.router.navigate(['./' + link]);
    if (link === this.infoService.itemsHome.link) {
      this.iniHome();
    }
  }

  iniHome() {
    this.selectedPage = null;
    const x = document.getElementsByClassName('sidebarElement');
    for (let i = 0; i < x.length; i++) {
      x[i].className = 'sidebarElement';
    }
    const y = document.getElementsByClassName('menuLabel');
    for (let i = 0; i < y.length; i++) {
      y[i].className = 'menuLabel';
    }
  }

  /**
   * Setta il timezone al valore indicato
   * @param myTime
   */
  setTimezone(myTime: SelectItem) {
    if (myTime.label === this.getTimezone()) {
      return;
    }
    TableService.loading = true;
    this.infoService.setTimezone(myTime.value, this.infoService.user.id).subscribe(() => {
      // TableService.loading = false;
      location.reload();
    }, () => {TableService.loading = false})
  }

  getTimezone() {
    if (this.infoService.user) {
      return this.infoService.user.timezone;
    }
  }

  switchUser(accountId: number) {
    this.loading = true;
    this.infoService.switchUser(accountId).subscribe(res => {
      UserAs.userAsID = accountId;
      JwtPermissions.updateAllInUserPermissions(res.permissions);
      this.identifyResult(res);
      this.loading = false;
    }, (err) => {
      this.loading = false;
      if (err && err.error) {
        this.messageService.add({severity: 'error', summary: 'Error', detail: err.error ? err.error.error : 'An error occurred'});
      }
    });
  }

// Apre modal 'cambia utente'
  showModalChangeUser() {
    this.userSelected = undefined;
    this.stack.push(this.changeUserModal);
    this.clearFilters();
  }

  userChange() {
    this.setRecentUser(this.userSelected);
    TableService.loading = true;
    this.infoService.getIdentify(1, this.userSelected).subscribe((res) => {
      TableService.loading = false;
      this.stack.pop();
      UserAs.userAsID = this.userSelected;
      JwtPermissions.updateAllInUserPermissions(res.permissions);
      this.identifyResult(res);
    }, () => {
      TableService.loading = false;
    })
  }

  // Ritorna all'account padre
  returnFather() {
    this.loading = true;
    this.infoService.getIdentify(1).subscribe(res => {
      UserAs.emptyFields();
      this.showReturnFather = false;
      JwtPermissions.updateAllPermissions();
      this.identifyResult(res);
      this.loading = false;
    }, () => {
        this.loading = false;
      });
  }

  // Controllo se mobile o desktop
  onResize() {
    const ismob = this.pageService.isMobile(500);
    if (document.getElementById('panelFiltri')) {
      if (!ismob) {
        document.getElementById('panelFiltri').className = 'panel-collapse collapse in';
      } else {
        document.getElementById('panelFiltri').className = 'panel-collapse collapse';
      }
    }
  }

  onSelectUser(event) {
    if (event.keyCode === 13 && this.userSelected !== null && this.userSelected !== undefined) {
      this.userChange();
      this.stack.pop();
    }
  }

  public identifyResult(res: ResponseInfo) {
    if (UserAs.userAsID) {
      localStorage.setItem(Constants.USER_KEY_LOCALSTORAGE, UserAs.userAsID + '');
      this.showReturnFather = true;
    } else {
      localStorage.removeItem(Constants.USER_KEY_LOCALSTORAGE);
    }
    this.username = res.user.username;
    this.infoService.userIniOperations(res.user);
    this.elementLoader(res.page_content, true);
    this.router.navigate(['/' + Constants.LINK_HOME]);
  }

  getElement(language: number, refreshPage?: boolean) {
    this.loading = true;
    this.infoService.getElements(1).subscribe((res) => {
      this.elementLoader(res, refreshPage);
    },
      (error) => {
        this.loading = false;
      });
  }

  elementLoader(res: PageContent, refreshPage) {
    this.loading = false;
    const notPresent = this.infoService.elements === undefined;
    this.infoService.elementLoader(res);
    this.infoService.elements = res;
    this.timezones = res.timezones;
    if (this.timezones && this.timezones.length > 0) {
      this.timezones = this.timezones.map(el => ({value: el.value, label: el.label, title: el.title}));
    }
    this.onResize();
    const url = this.router.url;
    this.selectedPage = this.findActualPage(url);
    if (this.selectedPage === undefined) {
      this.router.navigateByUrl('notPermitted');
    }
    if (notPresent) {
      this.infoService.logElementIn();
    }
    if (res.generic_permission && res.generic_permission.some(elem => elem === Constants.MASTER_USER_PERM) && JwtPermissions.admin && this.myUser.username?.indexOf(Constants.ANDREA_ONETAG_MAIL) < 0) {
      this.showSelectChild = true;
      this.infoService.getAllUsers().subscribe(elem => {
        for (const myMail of this.LIST_NO_LOTTOMATICA) {
          if (this.infoService.user.username.indexOf(myMail) >= 0) {
            elem = elem.filter(elem => elem.label.toLowerCase().indexOf('lottomatica') < 0);
          }
        }
        this.infoService.allUsers = elem;
        this.infoService.logAllUsersIn();
      });
    } else {
      this.showSelectChild = false;
    }
    this.getEntityInfo();
    this.loadUser();
    this.loadLogo();

    if (refreshPage) {
      this.resetPage();
    }
  }

  getAllUsers(): any[] {
    if (this.infoService.user.reviewer == 1) {
    // if (this.myUser.id === Constants.DEVOPS_ONETAG) {
      return this.infoService.allUsers;
    } else if (this.infoService.allUsers && this.infoService.allUsers.length > 0) {
      return this.infoService.allUsers.filter(elem => elem.type + '' !== Constants.ADMIN + '');
    }
    return [];
  }

  /**
   * @param myCallback : true se voglio chiamare la callback, false altrimenti
   * @param goToHome : true if you want to go to homepage
   */
  getUser(myCallback, goToHome?: boolean) {
    this.loading = true;
    this.infoService.getUser().subscribe((res) => {
      this.loading = false;
      if (UserAs.userAsID) {
        this.showReturnFather = true;
        TableService.loading = true;
        this.infoService.getPermissions(UserAs.userAsID).subscribe(perm => {
          TableService.loading = false;
          JwtPermissions.updateAllInUserPermissions(perm);
        }, () => {
          JwtPermissions.updateAllInUserPermissions(['api'])
          TableService.loading = false
        });
      }
      this.infoService.userIniOperations(res);
      this.loadUser();
      this.getElement(1);
      this.username = res.username;
      if (myCallback) {
        this.infoService.logUserIn();
      }
      if (goToHome) {
        this.router.navigate(['/' + Constants.LINK_HOME]);

        this.resetPage();
      }
      this.trackUser();
    },
      (error) => {
        this.loading = false;
        this.handleError(error);
      });
  }

  loadUser() {
    switch (this.infoService.user.language) {
      case 0: {
        this.translate.use('it');
        break;
      }
      case 1: {
        this.translate.use('en');
        break;
      }
    }
    if (JwtPermissions.admin) {
      this.usernameRole = '(admin)';
    } else {
      this.usernameRole = undefined;
    }
    this.myUserProfileList = this.allProfileList.filter(myElem => {
      if (JwtPermissions.admin) {
        return (!myElem.value || (myElem.value === this.infoService.user.type || myElem.value === Constants.ADMIN ||
          (myElem.value === -Constants.ADMIN && this.infoService.user.type !== Constants.ADMIN)));
      }
      return (!myElem.value || (myElem.value === this.infoService.user.type))
    })
    this.getConditionalCalls();
  }

  getConditionalCalls() {
    if (this.infoService.elements && this.infoService.elements.items  && this.infoService.elements.items.length > 0) {
      for (const el of this.infoService.elements.items) {
        if (el.subItems && el.subItems.length > 0) {
          for (const el2 of el.subItems) {
            if (el2.link === Constants.LINK_SEGMENTS_PART + '/' + Constants.LINK_SEGMENTS) {
              this.getSegmentsTypes();
            }
          }
        }
      }
    }
  }

  getSegmentsTypes() {
    this.tableService.getSegmentsTypes(this.infoService.user.language).subscribe(res => {
      SegmentTypes.SEGMENT_TYPES = res;
      SegmentTypes.SEGMENT_TYPES_SELECTABLE = res.map(elem => ({label: elem.type, value: elem.id, description: elem.description}));
      SegmentTypes.SEGMENT_TYPES_SELECTABLE_VOID_SEL = (res.map(elem => ({label: elem.type, value: elem.id, description: elem.description})));
    });
  }

  resetPage() {
    this.infoService.changingLang = true;
    setTimeout(() => {
      this.infoService.changingLang = false;
    }, 100);
  }

  getLanguage(language: string): number {
    switch (language) {
      case 'it': {
        return 0;
      }
    }
    return 1;
  }

  handleError(error) {
    this.logout();
  }

  getExpanded(): boolean {
    return this.infoService.bodyExpanded;
  }

  expand() {
    this.infoService.expand()
  }

  compress() {
    this.infoService.compress();
  }

  getWidthModal(): number {
    return TableService.getModalWidth();
  }

  isAdmin(): boolean {
    return JwtPermissions.admin;
  }

  canGoBack(): boolean {
    return this.showReturnFather || (UserAs.userAsID && this.infoService.user && !JwtPermissions.admin);
  }

  areThereItems(): boolean {
    return (this.infoService.elements && this.infoService.elements.items && this.infoService.elements.items.length > 0);
  }


  getClassSelected(userName: number): string {
    if (userName === this.userSelected) {
      return 'selectedUser';
    }
    return '';
  }

  clearFilters() {
    this.usersFiltered = this.getAllUsers();
    this.typeUserFiltered = 0;
    this.getRecentUsers();
  }

  getOneTypeUsers() {
    this.usersFiltered = this.getAllUsers().filter(us => us.type + '' === this.typeUserFiltered + '' || this.typeUserFiltered + '' === '0');
  }

  getRecentUsers() {
    const a = localStorage.getItem(UtilsFun.getHashCode(this.infoService.user.username) + Constants.SAVEDRECENTUSERS);
    let recents = [];
    if (a && a !== 'undefined' && a !== 'null') {
      recents = JSON.parse(localStorage.getItem(UtilsFun.getHashCode(this.infoService.user.username) + Constants.SAVEDRECENTUSERS));
    }

    this.recentUsers = [];

    if (recents) {
      for (let i = 0; i < recents.length; i++) {
        const u = this.getAllUsers().filter(res => res.value === recents[i]);
        if (u && u.length > 0) {
          this.recentUsers.push(u[0]);
        }
      }
    }
  }

  setRecentUser(id: number) {
    const a = localStorage.getItem(UtilsFun.getHashCode(this.infoService.user.username) + Constants.SAVEDRECENTUSERS);
    let recents = [];
    if (a && a !== 'undefined' && a !== 'null') {
      recents = JSON.parse(localStorage.getItem(UtilsFun.getHashCode(this.infoService.user.username) + Constants.SAVEDRECENTUSERS));
    }

    if (recents && recents.length > 0) {
      if (id === recents[0]) {
        recents[0] = id;
      } else if (id === recents[1]) {
        recents[1] = recents[0];
        recents[0] = id;
      } else if (id === recents[2]) {
        recents[2] = undefined;
        recents[1] = recents[0];
        recents[0] = id;
      } else {
        recents[2] = recents[1];
        recents[1] = recents[0];
        recents[0] = id;
      }
    } else {
      recents = [];
      recents.push(id);
    }
    localStorage.setItem(UtilsFun.getHashCode(this.infoService.user.username) + Constants.SAVEDRECENTUSERS, JSON.stringify(recents));
  }

  getAdBlockAdvisor() {
    if (!this.adBlockChecked) {
      const a = document.getElementById('adBlockID') as HTMLDivElement;
      if (a) {
        if (a.offsetHeight === 0) {
          const modal = document.getElementById('modalBlock') as HTMLDivElement;
          modal.style.opacity = '1';
          modal.style.display = 'block';
        }
        a.style.height = '0px';
        this.adBlockChecked = true;
      }
    }
  }

  closeAdBlockAdvisor() {
    const modal = document.getElementById('modalBlock') as HTMLDivElement;
    if (modal) {
      modal.style.opacity = '0';
      modal.style.display = 'none';
    }
  }

  getPublisherReport() {
    if (this.isPublisherReportVisible()) {
      const link = document.createElement('a');
      document.body.appendChild(link); // Firefox requires the link to be in the body
      link.download = '_';
      link.href = this.getReportStringToCopy();
      link.click();
    }
  }

  isPublisherReportVisible() {
    return (this.infoService.user && this.infoService.user.key && this.infoService.user.encryptedId);
  }

  getReportStringToCopy() {
    return 'https://www.onetag.com/api/stats/?pub_id=' + this.infoService.user.encryptedId +
    '&api_key=' + this.infoService.user.key + '&query_type=1';
  }

  showCopied() {
    this.messageService.add({severity: 'success', summary: 'Copied', detail: 'Copied successfully'});
  }

  getNotifyTimeLabel(timestamp) {
    const day = UtilsFun.getOnlyDate(timestamp);
    if (day === UtilsFun.getOnlyDate((new Date()).getTime())) {
      return 'Today';
    }
    return day;
  }

  isCampaignNotify(type: number) {
    switch (type) {
      case this.NOTIFY_BUYER_CAMP: case this.NOTIFY_PUBLISHER_CAMP: case this.NOTIFY_ALERT_CAMP: case this.NOTIFY_STATUS_CAMP: {
        return true;
      }
    }
    return false;
  }

  openBlacklist() {
    this.blackList = [];
    TableService.loading = true;
    this.infoService.getCampaignsBlacklist().subscribe(res => {
      this.blackList = res;
      this.displayBlacklist = true;
      TableService.loading = false;
    }, () => TableService.loading = false);
  }

  restoreCampaign(idCampaign: number) {
    TableService.loading = true;
    this.infoService.restoreCampaignNotification(idCampaign).subscribe(() => {
      const element = this.blackList.find(elem => elem.id_campaign === idCampaign);
      if (element) {
        this.messageService.add({
          severity: 'success', summary: 'Success',
          detail: 'Notifications about the ' + element.campaign_name + ' (' + element.id_campaign + ') campaign have been restored'});
      }
      this.blackList = this.blackList.filter(elem => elem.id_campaign !== idCampaign);
      TableService.loading = false;
    }, () => TableService.loading = false);
  }

  ifIsUnread(read) {
    if (read === 0) {
      return 'unread';
    }
    return 'read';
  }

  get myUser() {
    return this.infoService.user;
  }

  getColored(myStr: string) {
    return myStr.replace('ATTENTION', '<span class="yellowColor">Attention</span>')
      .replace('Stopped', '<span class="yellowColor">Stopped</span>')
      .replace('Activated', '<span class="yellowColor">Activated</span>')
      .replace('Modified', '<span class="yellowColor">Modified</span>')
      .replace('Alert', '<span class="yellowColor">Alert</span>')
      .replace('Created', '<span class="greenColor">Created</span>')
      .replace('Cloned', '<span class="greenColor">Cloned</span>')
      .replace('Error', '<span class="redColor">Error</span>');
  }

  get myElements(): PageContent {
    return this.infoService.elements;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
