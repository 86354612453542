import {JwtHelperService} from '@auth0/angular-jwt';
import {Injectable} from '@angular/core';
import {ResponseInfo} from './entities/ResponseInfo';
import {UserData} from './entities/UserData';
import {UserAs} from './UserAs';

@Injectable()
export class JwtPermissions {

  private static readonly ADMIN_LABEL = 'admin';
  private static readonly REFERRER_LABEL = 'referrer';
  private static readonly API_LABEL = 'api';
  private static readonly LOGGED_LABEL = 'logged';

  // Global Permissions
  public static admin: boolean;
  public static referrer: boolean;
  public static api: boolean;
  public static quiteLogged: boolean;


  private static readonly ID_TOKEN_NAME = 'id_token'; // Chiave del token
  private static readonly ACCESS_TOKEN_NAME = 'access_token'; // Chiave dell'access token
  private static readonly REFRESH_TOKEN_NAME = 'refresh_token'; // Chiave del refresh token
  private static jwtHelper: JwtHelperService;

  // Setta il nuovo token
  static setToken(myResponseInfo: ResponseInfo) {
    if (myResponseInfo.token) {localStorage.setItem(JwtPermissions.ID_TOKEN_NAME, myResponseInfo.token)}
    if (myResponseInfo.accessToken) {localStorage.setItem(JwtPermissions.ACCESS_TOKEN_NAME, myResponseInfo.accessToken)}
    if (myResponseInfo.refreshToken) {localStorage.setItem(JwtPermissions.REFRESH_TOKEN_NAME, myResponseInfo.refreshToken)}
    JwtPermissions.updateAllPermissions();
  }

  // Carica il token da localstorage e aggiorna i permessi
  static loadTokenFromLocalstorage() {
    JwtPermissions.updateAllPermissions();
  }

  // Ritorna il tokenID corrente
  static getCurrentTokenID(): string {
    return localStorage.getItem(JwtPermissions.ID_TOKEN_NAME);
  }

  // Ritorna l'access token corrente
  static getCurrentAccessToken(): string {
    return localStorage.getItem(JwtPermissions.ACCESS_TOKEN_NAME);
  }

  // Ritorna il refresh token corrente
  static getCurrentRefreshToken(): string {
    return localStorage.getItem(JwtPermissions.REFRESH_TOKEN_NAME);
  }

  // Controlla se la sessione corrente ha sempre il token giusto (Se cambio utente da un altro tab, avrò true)
  static checkCurrentToken(myUser: UserData): boolean {
    if (JwtPermissions.getCurrentTokenID() && JwtPermissions.getIdUserFromToken() && myUser && myUser.id && (myUser.id.toString() !== JwtPermissions.getIdUserFromToken()) && (myUser.id !== UserAs.userAsID)) {
      return true;
    }
    if (!JwtPermissions.getCurrentTokenID() && myUser && myUser.id) {
      return true;
    }
  }

  // Rimuove il token
  static removeToken() {
    if (localStorage.getItem(JwtPermissions.ID_TOKEN_NAME) != null) {
      localStorage.removeItem(JwtPermissions.ID_TOKEN_NAME);
    }
    if (localStorage.getItem(JwtPermissions.ACCESS_TOKEN_NAME) != null) {
      localStorage.removeItem(JwtPermissions.ACCESS_TOKEN_NAME);
    }
    if (localStorage.getItem(JwtPermissions.REFRESH_TOKEN_NAME) != null) {
      localStorage.removeItem(JwtPermissions.REFRESH_TOKEN_NAME);
    }
    JwtPermissions.updateAllPermissions();
  }

  static updateAllInUserPermissions(permissions: string[]) {
    JwtPermissions.admin = permissions && permissions.length > 0 && permissions.some(elem => elem === JwtPermissions.ADMIN_LABEL);
    JwtPermissions.referrer = permissions && permissions.length > 0 && permissions.some(elem => elem === JwtPermissions.REFERRER_LABEL);
    JwtPermissions.api = permissions && permissions.length > 0 && permissions.some(elem => elem === JwtPermissions.API_LABEL);
    JwtPermissions.quiteLogged = permissions && permissions.length > 0 && permissions.some(elem => elem === JwtPermissions.LOGGED_LABEL)
  }

  // Aggiorna tutti i permessi
  static updateAllPermissions() {
    JwtPermissions.admin = JwtPermissions.isAdmin();
    JwtPermissions.api = JwtPermissions.isLogged();
    JwtPermissions.quiteLogged = JwtPermissions.isQuiteLogged();
  }

  static getExpirationIDToken(): number {
    JwtPermissions.buildJwtHelper();
    return JwtPermissions.getDecodeTokenID()['exp'];
  }

  static getExpirationAccessToken(): number {
    JwtPermissions.buildJwtHelper();
    return JwtPermissions.getDecodeTokenAccess()['exp'];
  }

  static getExpirationRefreshToken(): number {
    JwtPermissions.buildJwtHelper();
    return JwtPermissions.getDecodeTokenRefresh()['exp'];
  }

  // Costruisce la classe di supporto
  private static buildJwtHelper() {
    if (JwtPermissions.jwtHelper === undefined) {
      JwtPermissions.jwtHelper = new JwtHelperService();
    }
  }

  private static getDecodeTokenID() {
    return JwtPermissions.jwtHelper.decodeToken(localStorage.getItem(JwtPermissions.ID_TOKEN_NAME));
  }

  private static getDecodeTokenAccess() {
    return JwtPermissions.jwtHelper.decodeToken(localStorage.getItem(JwtPermissions.ACCESS_TOKEN_NAME));
  }

  private static getDecodeTokenRefresh() {
    return JwtPermissions.jwtHelper.decodeToken(localStorage.getItem(JwtPermissions.REFRESH_TOKEN_NAME));
  }

  static getIdUserFromToken() {
    return JwtPermissions.getDecodeTokenID().preferred_username;
  }

  static getIdEntityFromToken() {
    return JwtPermissions.getDecodeTokenID().given_name;
  }

  static getCognitoUsernameFromToken() {
    const token = JwtPermissions.jwtHelper.decodeToken(localStorage.getItem(JwtPermissions.ID_TOKEN_NAME));

    return token['cognito:username'];
  }

  static getCognitoProviderNameFromToken() {
    const token = JwtPermissions.jwtHelper.decodeToken(localStorage.getItem(JwtPermissions.ID_TOKEN_NAME));

    if (token['identities']) {
      return token['identities'][0]['providerName'];
    } else {
      return '';
    }
  }

  // Funzioni di check dei permessi
  private static isLogged(): boolean {
    JwtPermissions.buildJwtHelper();
    return JwtPermissions.getDecodeTokenID() && JwtPermissions.getDecodeTokenID()['cognito:groups'].some(elem => elem === JwtPermissions.API_LABEL);
  }

  private static isAdmin(): boolean {
    JwtPermissions.buildJwtHelper();
    return JwtPermissions.getDecodeTokenID() && JwtPermissions.getDecodeTokenID()['cognito:groups'].some(elem => elem === JwtPermissions.ADMIN_LABEL);
  }

  private static isReferrer(): boolean {
    JwtPermissions.buildJwtHelper();
    return JwtPermissions.getDecodeTokenID() && JwtPermissions.getDecodeTokenID()['cognito:groups'].some(elem => elem === JwtPermissions.REFERRER_LABEL);
  }

  private static isQuiteLogged(): boolean {
    JwtPermissions.buildJwtHelper();
    return JwtPermissions.getDecodeTokenID() && JwtPermissions.getDecodeTokenID()['cognito:groups'].some(elem => elem === JwtPermissions.LOGGED_LABEL);
  }

}
