import {Injectable} from '@angular/core';
import {GlobalSettings} from '../../GlobalSettings';
import {Observable} from 'rxjs';
import {ErrorManager} from '../__helpers/errorManager';
import {HttpClient} from '@angular/common/http';
import {DealsBuyer} from '../__helpers/entities/DealsBuyer';
import {Deals} from '../__helpers/entities/Deals';
import {SelectItem} from 'primeng/api';
import {Advertiser} from '../__helpers/entities/Advertiser';
import {TableService} from '../services/table.service';
import { InfoService } from 'app/services/info.service';
import { Constants } from 'app/__helpers/entities/Constants';
import {DemandPartner} from "../_demandPartnersMVC/_helpers/DemandPartner";
import {ForecastTool} from "../__helpers/entities/ForecastTool";

@Injectable({
  providedIn: 'root',
})
export class DealsService {

  private readonly initPath = '/api/v1'
  private readonly baseURL = GlobalSettings.APIurl + this.initPath;

  /***************************************************** Url Deal Call ************************************************/
  private readonly dealURL = this.baseURL + '/deal';
  private readonly dealRevertDeleteURL = this.dealURL + '/revert-delete/';

  /************************************************** Url Deal Buyer Call *********************************************/
  private readonly changeDealStatusURL = GlobalSettings.APIurl + '/api/changeDealStatus';

  /***************************************************** Url Deal My Call *********************************************/
  private readonly getDealBidAmountURL = GlobalSettings.APIurl + '/api/report/getDealBidAmount';
  private readonly getPublishersAuctionPackageURL = this.baseURL + '/publisher/auction-package';

  /*************************************************** Conversion currency *********************************************/
  private readonly convertValueURL = GlobalSettings.APIurl + '/v1/currency/converter';

  private readonly curationDashboardTableURL = this.dealURL + '/reportCuration';

  /*************************************************** Exchange call*********************************************/
  private readonly demandPartnerDealDefaultValuesURL = GlobalSettings.APIurl + '/api/v1/exchange/deal/default/';

  /*************************************************** Forecast tool call*********************************************/
  private readonly dealForecastToolURL = GlobalSettings.APIV2url + '/v2/forecast';


  dealsChoice: SelectItem[];
  exchangeChoice: SelectItem[];
  allExchangeChoice: SelectItem[];

  constructor(private http: HttpClient, private tableService: TableService, private infoService: InfoService) {}

  isBuyerDeal() {
    return this.infoService.isBuyer() && !this.isParticularPublisher();
  }

  isParticularPublisher() {
    return this.infoService.user && (this.infoService.user.id === Constants.OT_INTEGRATION || this.infoService.user.id === Constants.MEDIAMATH_ADMIN);
  }

  getExchang(type?: number, active?: number) {
    this.tableService.getCompleteExchange(type, active).subscribe(res => {
      if (type !== undefined) {
        this.exchangeChoice = res;
        if (type === 1 && this.exchangeChoice) {
          this.exchangeChoice = this.exchangeChoice.filter(el => el.value !== 2);
        }
      } else {
        this.allExchangeChoice = res;
      }
    });
  }

  /*************************************************** Deal Buyer ********************************************************/

  addBuyerDeal(deal: DealsBuyer, entity?: number): Observable<DealsBuyer> {
    const params: any = {};
    if (entity !== undefined) { params['entity'] = entity.toString(); }
    return this.http.post<DealsBuyer>(this.dealURL, deal, {params: params}).catch(ErrorManager.handleError);
  }

  modifyBuyerDeal(deal: DealsBuyer, entity?: number): Observable<DealsBuyer> {
    const params: any = {};
    if (entity !== undefined) { params['entity'] = entity.toString(); }
    return this.http.post<DealsBuyer>(this.dealURL, deal, {params: params}).catch(ErrorManager.handleError);
  }

  deleteBuyerDeal(id: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    return this.http.delete(this.dealURL, {params: params}).catch(ErrorManager.handleError);
  }

  changeDealStatus(id: number, approval: number, entity?: number): Observable<any> {
    const params: any = {};
    if (entity !== undefined) { params['entity'] = entity.toString(); }
    return this.http.post<DealsBuyer>(this.changeDealStatusURL, {id: id, approval: approval}, {params: params}).catch(ErrorManager.handleError);
  }

  /***************************************************** Deal My ********************************************************/

  getDealsMy(entity?: number[], type?: number, showAsSelectItem?: boolean, auctionType?: number, audienceExtension?: boolean, status?: number, dealType?: string): Observable<Deals[]> {
    const params: any = {};
    if (entity !== undefined) { params['entity'] = entity.toString(); }
    if (type !== undefined) { params['type'] = type.toString(); }
    if (auctionType !== undefined) { params['auctionType'] = auctionType.toString(); }
    if (showAsSelectItem) { params['showAsSelectItem'] = true }
    if (audienceExtension) { params['audienceExtension'] = audienceExtension }
    if (status !== null && status !== undefined) { params['statusDeal'] = status }
    if (dealType !== null && dealType !== undefined) { params['dealType'] = dealType }
    return this.http.get<Deals[]>(this.dealURL, {params: params}).catch(ErrorManager.handleError);
  }

  getDeal(id: number): Observable<Deals> {
    return this.http.get<Deals>(this.dealURL + '/detail/' + id).catch(ErrorManager.handleError);
  }

  addDealPublisher(deal: Deals, entity?: number): Observable<Deals> {
    const params: any = {};
    if (entity !== undefined) { params['entity'] = entity.toString(); }
    return this.http.post<Deals>(this.dealURL, deal, {params: params});
  }

  getDealReportTableCurator(ids: number[]): Observable<{ id: number, dealSpend: number, dealResponseRate: number }[]> {
    return this.http.post<{ id: number, dealSpend: number, dealResponseRate: number }[]>(this.curationDashboardTableURL, {ids: ids});
  }

  setStatusDeal(id: number, command: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    if (command !== undefined) { params['command'] = command.toString(); }
    return this.http.put(this.dealURL, '', {params: params}).catch(ErrorManager.handleError);
  }

  modifyDealPublisher(deal: Deals, entity?: number): Observable<Deals> {
    const params: any = {};
    if (entity !== undefined) { params['entity'] = entity.toString(); }
    return this.http.post<Deals>(this.dealURL, deal, {params: params}).catch(ErrorManager.handleError);
  }

  deleteDealPublisher(id: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    return this.http.delete(this.dealURL, {params: params}).catch(ErrorManager.handleError);
  }

  revertDeleteDealPublisher(id: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) {
      return this.http.patch(this.dealRevertDeleteURL + id.toString(), {params: params}).catch(ErrorManager.handleError);
    }
    return null;
  }

  getDealBidAmount(ids: number[], type?: number): Observable<{ id: number, bidrequest: string, bidresponse: string }[]> {
    const params: any = {};
    if (type !== undefined) { params['type'] = type.toString(); }
    return this.http.post<{ id: number, bidrequest: string, bidresponse: string }[]>(this.getDealBidAmountURL, {ids: ids}, {params: params});
  }

  convertValue(from: string, to: string, amount: number): Observable<any> {
    const params: any = {};
    if (from !== undefined) { params['from'] = from.toString(); }
    if (to !== undefined) { params['to'] = to.toString(); }
    if (amount !== undefined) { params['amount'] = amount; }
    return this.http.get<Advertiser[]>(this.convertValueURL, {params: params});
  }

  getDemandPartnerDealDefaultValues(exchange: number): Observable<any> {
    return this.http.get<DemandPartner[]>(this.demandPartnerDealDefaultValuesURL + exchange);
  }

  modifyOVDealPublisher(deal: Deals, entity?: number): Observable<Deals> {
    const params: any = {};
    if (entity !== undefined) { params['entity'] = entity.toString(); }
    return this.http.patch<Deals>(this.dealURL + '/' + deal.id, deal, {params: params}).catch(ErrorManager.handleError);
  }

  getDealForecastToolData(request: ForecastTool): Observable<any> {
    return this.http.post<any>(this.dealForecastToolURL, request);
  }
}
