import {Component, Inject, OnInit} from '@angular/core';
import {NavigationEnd, Router, NavigationStart, NavigationCancel} from '@angular/router';
import {InfoService} from 'app/services/info.service';
import {TableService} from '../services/table.service';
import {UtilsFun} from '../__helpers/entities/UtilsFun';
import {environment} from '../../environments/environment';
import {Title} from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import {VersionService} from '../services/versionCheck.service';
import {DashboardService} from '../services/dashboard.service';
import {Constants} from '../__helpers/entities/Constants';
import { CerCampaignService } from 'app/_cerberusMVC/cerCampaign.service';
import { ErrorManager } from 'app/__helpers/errorManager';
import {JwtPermissions} from '../__helpers/jwtPermissions.service';
import {UserAs} from '../__helpers/UserAs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  currentUrl = '';
  isNewCampaign: boolean;
  isDev: boolean;

  myHeavyLoading: boolean;

  TableService = TableService;
  ErrorManager = ErrorManager;

  constructor(@Inject(DOCUMENT) private _document: HTMLDocument, private versionService: VersionService,
              private dashboardService: DashboardService, private router: Router, public infoService: InfoService, private titleService: Title) {

    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.myHeavyLoading = true;
      } else if ( val instanceof NavigationEnd) {
        this.myHeavyLoading = false;
        this.currentUrl = val.url;

        // Serve una condizione più precisa
        if (this.currentUrl.startsWith('/' + Constants.LINK_INVITE_PUBLISHER) || this.currentUrl.startsWith('/' + Constants.LINK_INVITE_GOOGLE_PUBLISHER) || this.currentUrl.startsWith('/' + Constants.LINK_INVITE_ADDITIONAL_USER)) {
          JwtPermissions.removeToken();
          return;
        }
        const isChimera = this.currentUrl && (this.currentUrl.indexOf('/' + Constants.LINK_NEW_CAMPAIGN) !== -1);
        const isCerberus = this.currentUrl && (this.currentUrl.indexOf('/' + Constants.LINK_CAMPAIGN_CERBERUS) !== -1);
        this.isNewCampaign = isChimera || isCerberus;
        if (environment.production) {
          this.versionService.checkVersion(environment.versionCheckURL);
        }
        window.scrollTo(0, 0);
        CerCampaignService.TARGETING_COUNTER = -1; // Id negativi per i targeting
      } else if (val instanceof NavigationCancel) {
        this.myHeavyLoading = false;
      }
    });
  }

  ngOnInit(): void {
    console.log('\n' +
      '                                \n' +
      '                   (            \n' +
      '                   )            \n' +
      '             (  ( /(   ) (  (   \n' +
      '  (   (     ))\\ )\\()| /( )\\))(  \n' +
      '  )\\  )\\ ) /((_|_))/)(_)|(_))\\  \n' +
      ' ((_)_(_/((_)) | |_((_)_ (()(_) \n' +
      '/ _ \\ \' \\)) -_)|  _/ _` / _` |  \n' +
      '\\___/_||_|\\___| \\__\\__,_\\__, |  \n' +
      '                        |___/   ');

    JwtPermissions.loadTokenFromLocalstorage();
    const userId = localStorage.getItem(Constants.USER_KEY_LOCALSTORAGE);
    if (userId && !isNaN(Number(userId))) { UserAs.userAsID = Number(userId)}
    this.checkVersion();
    const elementFavicon = this._document.getElementById('applicationFavicon');
    if (UtilsFun.matchDestinationCarat()) {
      this.titleService.setTitle( 'DAN' );
      if (elementFavicon) { elementFavicon.setAttribute('href', 'assets/imgs/favicon3.ico'); }
    } else if (UtilsFun.matchDestinationTest()) {
      this.titleService.setTitle('Onetag Platform');
      if (elementFavicon) { elementFavicon.setAttribute('href', 'assets/imgs/favicon_test.ico'); }
    } else if (UtilsFun.matchDestinationDev()) {
      this.titleService.setTitle('Onetag Platform');
      this.isDev = true;
      if (elementFavicon) { elementFavicon.setAttribute('href', 'assets/imgs/favicon_dev.ico'); }
    } else if (UtilsFun.matchDestinationSandbox()) {
      this.titleService.setTitle('Ot Platform SB');
      this.isDev = true;
      if (elementFavicon) { elementFavicon.setAttribute('href', 'assets/imgs/favicon_sand.ico'); }
    } else if (UtilsFun.matchDebug()) {
      this.titleService.setTitle('Onetag Platform');
      if (elementFavicon) { elementFavicon.setAttribute('href', 'assets/imgs/favicon_debug.ico'); }
      this.isDev = true;
    } else {
      this.titleService.setTitle('Onetag Platform');
      if (elementFavicon) { elementFavicon.setAttribute('href', '../../favicon.ico'); }
    }
  }

  isLoggedIn() {
    return JwtPermissions.api;
  }

  checkVersion() {
    if (environment.production) {
      this.versionService.checkVersion(environment.versionCheckURL);
    }
    if (localStorage.getItem(Constants.USER_KEY_LOCALSTORAGE) !== (UserAs.userAsID ? UserAs.userAsID + '' : null)) {
      location.reload();
    }
    if (JwtPermissions.checkCurrentToken(this.infoService.user)) {
      location.reload();
    }
    setTimeout(() => {
      this.checkVersion();
    }, 10000);
  }

  isEventBusLoaded() {
    return this.dashboardService.loadEvent;
  }

}
